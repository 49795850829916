.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body{
  overflow-y: auto;
}

.autoCustomSelect > div > div:first-child {
  padding-bottom: 7.6px;
  padding-top: 7.6px;
  background-color: #edf2f6;
}

.mybarrow {
  cursor: pointer;
  height: 120px;
  left: 5%;
  position: fixed;
  top: 30%;
  transform: translateX(-50%) translateY(-50%) rotate(180deg) scale(0.7);
  transition: transform 0.1s;
  width: 80px;
}
.mybarrow-top,
.mybarrow-bottom {
  background-color: #666;
  height: 4px;
  left: -5px;
  position: absolute;
  top: 50%;
  width: 100%;
}
.mybarrow-top:after,
.mybarrow-bottom:after {
  background-color: #00989d;
  content: '';
  height: 100%;
  position: absolute;
  top: 0;
  transition: all 0.15s;
}
.mybarrow-top {
  transform: rotate(45deg);
  transform-origin: bottom right;
}
.mybarrow-top:after {
  left: 100%;
  right: 0;
  transition-delay: 0s;
}
.mybarrow-bottom {
  transform: rotate(-45deg);
  transform-origin: top right;
}
.mybarrow-bottom:after {
  left: 0;
  right: 100%;
  transition-delay: 0.15s;
}
.mybarrow:hover .mybarrow-top:after {
  left: 0;
  transition-delay: 0.15s;
}
.mybarrow:hover .mybarrow-bottom:after {
  right: 0;
  transition-delay: 0s;
}
.mybarrow:active {
  transform: translateX(-50%) translateY(-50%) scale(0.9);
}

@media screen and (max-width: 700px) {
  .mybarrow {
    bottom: -3rem;
    z-index: 1;
    top: unset;
    left: 10%;
  }
}

#landingPage:not(html) {
  /* solve the problem for ladning page when content shows after some delay on landing page safari */
  -webkit-transform: translate3d(0, 0, 0);
}