html,
body {
	scroll-behavior: smooth;
	overflow-x: hidden;
}

body {
	overflow-x: hidden;
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
		'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
		'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

button.chatcloud-widget-bubble{	
	bottom: 55px;
	right:30px !important;
	width:55px;
	height:55px
  }
button.chatcloud-widget-bubble > img{	
	height: 22px;
    margin: 16px;
    width: 22px;
  }
button.chatcloud-widget-bubble.woot--close::before{	
    height: 24px !important;
    left: 27px !important;
    top: 15px !important;
    width: 2px !important
  }

  button.chatcloud-widget-bubble.woot--close::after {
    height: 24px !important;
    left: 27px !important;
    top: 15px !important;
    width: 2px !important
  }

  button#fastbots-push-to-talk {
	border: 1px solid rgb(0 152 158) !important;
    background-color: rgb(0 152 158) !important;
    box-shadow: gray 0px 1px 6px 0px !important;
	bottom: 55px;
	right:30px !important;
	width:55px;
	height:55px
  }