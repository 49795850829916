h1 {
  font-size: xx-large;
}
h2 {
  font-size: x-large !important;
}
h3 {
  font-size: large !important;
}

.ql-align-center {
  text-align: center;
}
.ql-align-right {
  text-align: right;
}
.ql-align-left {
  text-align: left;
}
